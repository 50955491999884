// import {combineReducers} from 'redux'
// // import productReducer from "./products";
// // import {cartReducer} from "./cart";
// // import {wishListReducer} from "./wishList";
// // import compareListReducer from "./compare";


// const rootReducer = combineReducers({
//     // data: productReducer,
//     // cartList: cartReducer,
//     // wishList: wishListReducer,
//     // compareList: compareListReducer
// });

// export default rootReducer;

import { combineReducers } from 'redux';

const initialState = {
  user: null
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  user: userReducer
});

export default rootReducer;

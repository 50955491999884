import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Error from '../../components/404/404';
import { Helmet } from 'react-helmet';


const ErrorPage =() => {
    return(
        <Fragment>
             <Helmet>
                <title>404 | Up Dental of Manvel TX</title>
                <meta name="description" content="404 | Up Dental of Manvel TX" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-2'}/>
            <PageTitle pageTitle={'404'} pagesub={'404'} className={'wpo-page-title'}/> 
            <Error/>
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar/>
        </Fragment>
    )
};

export default ErrorPage;

import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
// import AboutPage from '../AboutPage/AboutPage';
// import TeamPage from '../TeamPage/TeamPage';
// import AboutPageStyle2 from '../AboutPage/AboutPageStyle2';
import ContactPage from '../ContactPage/ContactPage';
import AppoinmentPage from '../AppoinmentPage/AppoinmentPage';
import ErrorPage from '../ErrorPage/ErrorPage';
// import TestimonialPage from '../TestimonialPage/TestimonialPage';
import CaseGallery from '../CaseGallery/CaseGallery';
// import AllCombponentPage from '../AllComponentsPage/AllComponentPage';
import AllTeamMembersPage from '../TeamPage/AllTeamMembersPage';
import FaQPage from '../FaQPage/FaQPage';
import InsurancePage from '../InsurancePage/InsurancePage';
import DoctorProfilePage from '../DoctorProfilePage/DoctorProfilePage';
import LocationPage from '../LocationPage/LocationPage';
const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='appoinment' element={<AppoinmentPage />} />
          <Route path='dr-tran' element={<DoctorProfilePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='/404' element={<ErrorPage />} />
          <Route path='/*' element={<ErrorPage />} />
       
          <Route path='case-gallery' element={<CaseGallery />} />
          <Route path='all-team-members' element={<AllTeamMembersPage />} />
          <Route path='faq' element={<FaQPage />} />
          <Route path='insurance' element={<InsurancePage />} />
          <Route path='location' element={<LocationPage />}/>
          {/* <Route path='testimonial' element={<TestimonialPage />} /> */}
          {/* <Route path='about' element={<AboutPage />} />  */}
          {/* <Route path='team' element={<TeamPage />} /> */}
          {/* <Route path='about-us' element={<AboutPageStyle2 />} /> */}
           {/* <Route path='all-component' element={<AllCombponentPage />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;

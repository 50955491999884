import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const ShowAlertDiv = ({ alertMessage, severity, handleCloseAlert }) => {
    return (
        <div className="alert-container">
            <Alert  severity={severity} onClose={handleCloseAlert}>
                <AlertTitle sx={{fontWeight:1000}}>{severity === 'error' ? 'Error' : 'Success'}</AlertTitle>
                {alertMessage}
            </Alert>
        </div>
      
    );
  };

const ContactForm = () => {
    const {
        register, //track the changes for the input field value
        handleSubmit,
        watch,
        formState: { errors},
        reset
      } = useForm({
        defaultValues: {
           name: "",
           email: "",
           phone:"",
           message:"",
           subject:""
         }
     });
      
    const onSubmit = async (data) => {
        try {
            // console.log(data);
            // const backendUrl = `${process.env.REACT_APP_API_URL}/submit-contact-form` || "http://localhost:8000/submit-contact-form";
            let backendUrl = "http://localhost:8000/submit-contact-form";
            if (process.env.NODE_ENV==="production"){
                backendUrl = `${process.env.REACT_APP_NODEBACKEND_URL}/submit-contact-form`;
            }
            // console.log('backend URL ', backendUrl)
            const response = await fetch(backendUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
            const responseData = await response.json();
            // console.log("response from back end: ", responseData)
           
            if (response.ok) {
                reset(); // Reset form after successful submission
            //   alert(responseData.message);
                setAlertSeverity('success');
                setAlertMessage(responseData.message);
            } else {
            //   alert('Failed to submit form.');
                setAlertSeverity('error');
                setAlertMessage('Failed to submit form.');
            }
            setShowAlert(true);
            
        } catch (error) {
            // console.error('Error submitting form:', error);
            // alert('Failed to submit form.');
            setAlertSeverity('error');
            setAlertMessage('Failed to submit form.');
            setShowAlert(true);
            
        }
      }

    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = () => {
        setShowAlert(false);
      };

    // State to track if the phone number is valid
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    // Watch the phone input value
    const phoneValue = watch("phone");
    // Validate the phone number
    useEffect(() => {
        const phonePattern = /^\d{10}$/; // Validate 10-digit phone number
        setIsPhoneValid(phonePattern.test(phoneValue));
    }, [phoneValue]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={showAlert ? 'contact-validation-active blur' : 'contact-validation-active'} id='contact_form'  >
            
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <input
                                type="text"
                                placeholder="Your Name" 
                                {...register("name", {
                                    required: true
                                })}
                            />
                            {errors.name && <p className="errorMsg">Name is required</p>}
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <input
                                type="text"
                                placeholder="Your Email" 
                                {...register("email", {
                                    required: true,
                                    pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                })}
                            />
                            {errors.email && errors.email.type === "required" && (
                                <p className="errorMsg">Email is required.</p>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <p className="errorMsg">Email is not valid.</p>
                            )} 
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <input
                                type="tel"
                                placeholder="Your phone" 
                                {...register("phone", {
                                    // required: true,
                                    pattern: /^\d{10}$/,
                                })}
                            />
                            {errors.phone && errors.phone.type === "required" && (
                                <p className="errorMsg">Phone number is required.</p>
                            )}
                            {errors.phone && errors.phone.type === "pattern" && (
                                <p className="errorMsg">Phone number is not valid.</p>
                            )} 
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="form-field">
                            <select
                                type="text"
                                name="subject"
                                {...register("subject", {
                                    required: true
                                })}
                            >
                                <option disabled="disabled" value="" >Have you been to our office?</option>
                                <option>I am a new patient</option>
                                <option>I am an existing patient</option>
                            </select>
                            {errors.subject && errors.subject.type === "required" && <p className="errorMsg">Please select one option</p>}
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <textarea
                            // name="message"
                            placeholder="Message"
                            rows="1" 
                            {...register("message", {
                                required: true
                            })}
                        />
                        {errors.message && <p className="errorMsg">Message is required.</p>}     
                    </div>
                </div>
                
                {isPhoneValid &&  <div className="consent_checkbox">
                    <label className="checkbox-label"> 
                        <input
                            type="checkbox"
                            {...register("optInTextMessages", { required: "You must consent to receive text messages" })}
                        />
                            <div>
                                <div className='consent-text'>
                                    
                                        By submitting this form and signing up for texts, you consent to receive text messages of any questions from Up Dental of Manvel at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP. Reply HELP for help. Opt out at any time.
                                    <br></br>                              
                                    <a href="/files/UpDentalofManvel_privacy_policy.pdf" target="_blank"> Read our Privacy Policy</a>
                                </div>
                                

                                {errors.optInTextMessages && errors.optInTextMessages.type === "required" && (
                                <p className="errorMsg">You must consent to receive text messages.</p>
                                )} 
                            </div>                          
                            
                    </label>
                </div>}
                <div className="submit-area">
                    <button type="submit" className="theme-btn"> Submit Your Message</button>
                </div>
            </form >
        {showAlert && (
            <ShowAlertDiv
                severity={alertSeverity}
                alertMessage={alertMessage}
                handleCloseAlert={handleCloseAlert}
            />
        )}
        </div>
       
    )
}

export default ContactForm;
import insuranceImg1 from '../images/insurance/aetna.png.webp'
import insuranceImg2 from '../images/insurance/AmeritasLogo.png.webp'
import insuranceImg3 from '../images/insurance/bcbs.png.webp'
import insuranceImg4 from '../images/insurance/cigna.png.webp'
import insuranceImg5 from '../images/insurance/Insurance-delta-dental.png.webp'
import insuranceImg6 from '../images/insurance/guardian.png.webp'
import insuranceImg7 from '../images/insurance/humana.png.webp'
import insuranceImg8 from '../images/insurance/united-healthcare.png.webp'
// import insuranceImg8 from '../images/insurance/metlife.png.webp'


const insuranceImgs = [insuranceImg1,insuranceImg2,insuranceImg3,insuranceImg4,insuranceImg5,insuranceImg6,insuranceImg7,insuranceImg8
 ]

export default insuranceImgs;
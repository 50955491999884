import React from 'react'
import teams from '../../api/team_members'
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Slide } from "react-awesome-reveal";
import sliderSettings from '../../api/sliderSetting';
import Slider from "react-slick";
import 'swiper/css';
import 'swiper/css/navigation';
const OneMember = ({member}) => {
    return (
        <div className="onemember-wrapper">
            <div className="onemember">
                <div className="img-holder">
                    <img src={member.tImg} alt="" />
                </div>
                <div className="member-name">  
                    <h2>{member.name}</h2>                                           
                    <span>{member.title}</span>
                </div>
            </div>
        </div>
    )
}

export const AllTeamMembersSlider = (props) => {
    // for home page
    const sliderSettings_ = { ...sliderSettings, slidesToShow: 4  };
    
    return (
        <section className={`wpo-team-section  ${props.class}`}>
            <div className="container">
                <div className="wpo-section-title">
                    <span>Our Team</span>
                    <h2>Meet the teams </h2>
                </div>
                {/* SHOWING ALL TEAMS MEMBERS WITH SLIDE IF MORE THAN 4 */}
 
                    <Slider {...sliderSettings_} className="testimoni-slider">
                        {
                            teams.map((member, memberIdx) =>
                                <OneMember key={memberIdx} member={member}/> 
                            )
                        }
                    </Slider>
            </div>
        </section>
    )
}
// export const AllTeamMembersSlider = (props) => {
//     const sliderSettings_ = { ...sliderSettings, slidesToShow: 4  };
//     return (
//         <section className={`wpo-team-section  ${props.class}`}>
//         {/* <section className="wpo-team-section pb-70"}"> */}
//         {/* <section className={`wpo-team-section section-padding ${props.tClass}`}> */}
//             <div className="container">
//                 <div className="wpo-section-title">
//                     <span>Our Team</span>
//                     <h2>Meet the teams </h2>
//                 </div>
//                 {/* SHOWING ALL TEAMS MEMBERS WITH SLIDE IF MORE THAN 4 */}
 
//                     <Slider {...sliderSettings_} className="testimoni-slider">
//                         {
//                             teams.map((member, memberIdx) =>
//                                 <OneMember key={memberIdx} member={member}/> 
//                             )
//                         }
//                     </Slider>

               

//             </div>
//         </section>
//     )
// }


const AllTeamMembers = () => {
    return (
        <section className='wpo-team-section'>
        {/* <section className={`wpo-team-section section-padding ${props.tClass}`}> */}
            <div className="container">
                <div className="wpo-section-title">
                    <h2>Meet the teams’s</h2>
                </div>
                {/* SHOWING ALL TEAMS MEMBERS WITH SLIDE IF MORE THAN 4 */}

                <div className='swiper-wrapper'>
                    <Swiper 
                        modules={[Navigation, A11y]}
                        spaceBetween={20}
                        slidesPerView={4}
                        loop={true}
                        pagination={{ clickable: true }}
                        speed={1000}
                        parallax={true}
                        navigation
                        // scrollbar-clickable={true} 
                        // mousewheel-invert={true}
                    >
                        {teams.map((member, memberIdx) => (
                           
                            <SwiperSlide  key={memberIdx}>
                                <div className='img-holder'>
                                    <img  src={member.tImg} alt=""></img>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <h5> {member.name}</h5>
                                   
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>

            </div>
        </section>
    )
}

export const AllTeamMembersNoSlide = (props) => {
    return (
        <section className={`wpo-team-section section-padding ${props.tClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            {/* <span>Meet Great Doctor’s</span> */}
                            <h2>Up Dental of Manvel team</h2>
                            {/* <p>Description of team here</p> */}
                        </div>
                    </div>
                </div>
                <div className="team-wrap">
                    <div className="row">
                        {teams.slice(0, teams.length).map((team, tm) => (
                            <div className="col-lg-4 col-md-6 col-12" key={tm}>
                                <Slide direction="up" duration={team.animation} triggerOnce="true">
                                    <div className="team-single">
                                        <div className="team-boder-shapes-1">
                                            <div className="team-single-img">
                                                <img src={team.tImg} alt="dental office member" />
                                            </div>
                                            <div className="team-single-text">    
                                                <h2>{team.name}</h2>                                         
                                                <span>{team.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AllTeamMembers;
import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
// import PageTitle from '../../components/pagetitle/PageTitle.js'

import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Footer from '../../components/footer/Footer.js'
import { AllTeamMembersNoSlide } from '../../components/TeamSection/AllTeamMembers.js';
import { Helmet } from 'react-helmet';
// import TeamSectionS2 from '../../components/TeamSectionS2/TeamSectionS2.js';
// import TeamSection from '../../components/TeamSection/TeamSection.js';
const AllTeamMembersPage = (props) => {

    return (
        <Fragment>
            <Helmet>
                <title>Meet the team | Up Dental of Manvel TX</title>
                <meta name="description" content="Meet the team | Up Dental of Manvel TX" />
                <meta name="keywords" content="dental, dentist, Manvel, treatment, patients, office" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-2'} />
            {/* <PageTitle pageTitle={'Up Dental of Manvel Team'} pagesub={'Team'} className={'wpo-page-title'} /> */}
            <AllTeamMembersNoSlide />
            {/* <TeamSection /> */}
            {/* <TeamSectionS2 /> */}
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AllTeamMembersPage;

// const officeInfo = {
//     address: "7218 Masters, Manvel, TX 77578",
//     phone: "(281) 519-7006",
//     email: "management.updentalofmanvel@gmail.com",
//     schedule : [
//         { day: 'Monday', day_short: 'MO', hours: '3:00 PM - 9:00 PM' },
//         { day: 'Tuesday', day_short: 'TU', hours: '3:00 PM - 9:00 PM' },
//         { day: 'Wednesday', day_short: 'WE', hours: '3:00 PM - 9:00 PM' },
//         { day: 'Thursday', day_short: 'TH', hours: '3:00 PM - 9:00 PM' },
//         { day: 'Friday', day_short: 'FR', hours: 'Closed' },
//         { day: 'Saturday', day_short: 'SA', hours: '9:00 AM - 3:00 PM' },
//         { day: 'Sunday', day_short: 'SU', hours: 'Closed' },
//     ]
// }
const officeInfo = {
    address: "7218 Masters, Manvel, TX 77578",
    phone: "(281) 519-7006",
    email: "management"+"."+"updentalofmanvel"+"@"+"gmail"+"."+"com",
    schedule : [
        { day: 'Monday', day_short: 'MO', hours: '9:00 AM - 5:00 PM' },
        { day: 'Tuesday', day_short: 'TU', hours: '4:00 PM - 10:00 PM' },
        { day: 'Wednesday', day_short: 'WE', hours: '4:00 PM - 10:00 PM' },
        { day: 'Thursday', day_short: 'TH', hours: '4:00 PM - 10:00 PM' },
        { day: 'Friday', day_short: 'FR', hours: '9:00 AM - 5:00 PM' },
        { day: 'Saturday', day_short: 'SA', hours: 'Closed' },
        { day: 'Sunday', day_short: 'SU', hours: 'Closed' },
    ]
}
export default officeInfo;
import React, {Fragment} from 'react';
// import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import AppoinmentSec2 from '../../components/AppoinmentSec2/AppoinmentSec2';
import { Helmet } from 'react-helmet';

const AppoinmentPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Make an Appoinment | Up Dental of Manvel TX</title>
                <meta name="description" content="Make an Appoinment | Up Dental of Manvel TX" />
                <meta name="keywords" content="dental, dentist, Manvel, treatment, patients, office, appointment" />
                {/* <link rel="canonical" href="https://www.updentalofmanvel.com/appoinment" /> */}
            </Helmet>
            <Navbar hclass={'wpo-header-style-2'}/>
            {/* <PageTitle pageTitle={'Appoinment'} pagesub={'Appoinment'} className={'wpo-page-title'}/>  */}
            <AppoinmentSec2/> 
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};

export default AppoinmentPage;

import React, {useState} from "react";
import dentalServices from "../../api/dentalServices";

const OneDentalService = ({icon, serviceName,  description, isActive, onToggle}) => {

    const [showContent, setShowContent] = useState(false);
    const toggleContent = () => {
        setShowContent(!showContent);
        onToggle()
      };
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 col">
            <div className="icon-container">
                <div className='icon-img-container'>
                    <img className="icon-img" src={icon} alt="" />
                </div>
                
                <div className="icon-service-name">
                    <span>{serviceName}</span>
                    {/* <div className="circle-click" onClick={onToggle}>
                        <div onClick={toggleContent} className={isActive&&showContent ? "plus-sign rotated" : "plus-sign"}>+</div>
                    </div> */}

                    <div className="circle-click" onClick={toggleContent} >
                        <div  className={isActive&&showContent ? "plus-sign rotated" : "plus-sign"}>+</div>
                    </div>
                    
                    {isActive && showContent &&
                        // <div className="service-description">
                        <div className={`service-description ${showContent ? 'active' : ''}`}>
                            <p>{ description}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
const DentalServicesSectionStyle2 = () => {
    const [showMore, setShowMore] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null);

    const handleToggle = (component) => {
        setActiveComponent(component);
      };

    const toggleContent = () => {
        setShowMore(!showMore);
      };
    
    return (
        <section className='wpo-department-section section-padding  section-border-bold'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            {/* <span></span> */}
                            {/* <h2>FEATURED DENTAL CARE</h2> */}
                            <h1>Featured Dental Care</h1>
                            <p>
                            At Up Dental of Manvel, we believe in comprehensive treatment planning. Our plan usually includes the mix of options listed below and multiple alternatives for our patients to choose from. We focus on providing the most informational knowledge of the highest quality to our patients and let the final decision making power rest in our patients.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="services-wrapper">
                    {/* LIST OF SERVICES */}
                    <div className="row align-items-center">
                    {/* <div className="row justify-content-center align-items-center"> */}
                        {
                            dentalServices.slice(0, showMore ? dentalServices.length: 8).map((service, idx) => (
                                <OneDentalService key={idx} 
                                icon={service.icon} 
                                serviceName={service.serviceName}
                                description={service.description}
                                isActive={activeComponent === service.serviceName}
                                onToggle={() => handleToggle(service.serviceName)}
                                />
                            ))
                        }
                    </div>
                    <div className="service-btn-container">
                        <button onClick={toggleContent} 
                            // className={showMore?'theme-btn-accent clicked':'theme-btn-accent'}
                            className="theme-btn-accent"
                        >
                            {/* More Services */}
                            {showMore?'Show Less':'More Services'}
                        </button>
                    </div>
                </div>
                
            </div>
        </section>

    );
}

export default DentalServicesSectionStyle2;